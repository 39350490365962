<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useBrowserLocation } from "@vueuse/core";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VTabBar from "@magnit/core/src/components/VTabBar/VTabBar.vue";
import LogoIcon from "@magnit/icons/src/assets/icons/v1-24-outline-logo.svg";
import CatalogIcon from "@magnit/icons/src/assets/icons/v1-24-outline-catalog.svg";
import CouponIcon from "@magnit/icons/src/assets/icons/v1-24-outline-promo.svg";
import CardIcon from "@magnit/icons/src/assets/icons/v1-24-outline-card-v1.svg";
import UserIcon from "@magnit/icons/src/assets/icons/v1-24-outline-profile.svg";
import type {
  ITabBarItem,
  ITabBarPayload,
} from "@magnit/core/src/components/VTabBar/VTabBar.types.js";
import { Routes } from "~/utils/routes";

enum NavName {
  Main = "Main",
  Coupons = "Coupons",
  QrCode = "QrCode",
  Profile = "Profile",
  Catalog = "Catalog",
}

const route = useRoute();
const router = useRouter();
const location = useBrowserLocation();

const navItems: ITabBarItem<NavName>[] = [
  {
    name: NavName.Main,
    icon: LogoIcon,
    text: "Главная",
    link: Routes.Main,
  },
  {
    name: NavName.Catalog,
    icon: CatalogIcon,
    text: "Каталог",
    link: Routes.Categories,
  },
  {
    name: NavName.Coupons,
    icon: CouponIcon,
    text: "Купоны",
    link: Routes.Coupons,
  },
  {
    name: NavName.QrCode,
    icon: CardIcon,
    text: "Карта",
    link: Routes.QrCode,
  },
  {
    name: NavName.Profile,
    icon: UserIcon,
    text: "Профиль",
    link: Routes.Profile,
  },
].reduce((acc, item) => {
  const host = location.value.host ?? "";
  if (item.name === NavName.Catalog && host.includes("v1.market")) {
    return acc;
  }

  return [...acc, item];
}, [] as ITabBarItem<NavName>[]);

const { send } = AnalyticManager;

const active = computed<NavName | null>(() => {
  if (route.path.startsWith(Routes.Main)) {
    return NavName.Main;
  }
  if (
    route.path.startsWith(Routes.Catalog) ||
    route.path.startsWith(Routes.CatalogSearch) ||
    route.path.startsWith(Routes.Categories) ||
    route.path.startsWith(Routes.CatalogProduct)
  ) {
    return NavName.Catalog;
  }
  if (route.path.startsWith(Routes.Coupons)) {
    return NavName.Coupons;
  }
  if (route.path.startsWith(Routes.QrCode)) {
    return NavName.QrCode;
  }
  if (route.path.startsWith(Routes.Profile)) {
    return NavName.Profile;
  }
  return null;
});

const onClick = (payload: ITabBarPayload) => {
  send(`TapBar:${payload.name}:Click`);
  router.push(payload.link);
};

onMounted(() => {
  send("TapBar:View");
});
</script>

<template>
  <VTabBar :items="navItems" :active="active" @on-click="onClick($event)" />
</template>
