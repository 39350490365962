<script setup lang="ts">
import type {
  ICategory,
  IProduct,
} from "@magnit/unit-catalog/src/unit-catalog.types";
import VHeaderSearch from "@magnit/unit-header/src/parts/Search.vue";
import VSearchResults from "@magnit/unit-header/src/parts/SearchResults.vue";
import VSearchSkeleton from "@magnit/unit-header/src/parts/SearchSkeleton.vue";
import VCatalogNavigation from "@magnit/unit-catalog/src/components/VCatalogNavigation.vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import { SEARCH_CHAR_START, SUGGESTS_LIMIT } from "~/constants/search";
import type { ICatalogBFFGoodsSearchV2Params } from "~/typings/api/goods";

const route = useRoute();
const router = useRouter();
const openSearchSuggest = ref(false);
const searchFocused = ref(false);
const searchValue = ref((route.query.term as string) || "");
const categoriesStore = useCategoriesStore();

const SUGGESTS_COUNT = 6;

const { requestProducts } = goodsApi();

const items = ref<IProduct[]>([]);
const status = ref<"initial" | "pending" | "success" | "error">("initial");
const clearSuggests = (withError?: boolean) => {
  items.value = [];
  status.value = withError ? "error" : "initial";
};

const closeError = () => {
  openSearchSuggest.value = false;
};

const checkSearchValue = () => {
  if (searchValue.value && searchValue.value.length >= SEARCH_CHAR_START)
    return true;
  clearSuggests();
};
const fetchSuggests = async () => {
  if (!checkSearchValue()) return;

  status.value = "pending";

  try {
    const allSuggests: IProduct[] = [];
    const goodsPayload: ICatalogBFFGoodsSearchV2Params = {
      term: searchValue.value,
      pagination: { offset: 0, limit: SUGGESTS_LIMIT },
      sort: DEFAULT_SORT_BFF_GOOD,
    };
    const goods = await requestProducts(
      goodsPayload,
      getObjectHash(goodsPayload),
    );
    if (goods.status.value === "success") {
      allSuggests.push(...(goods.data.value?.goods || []));
    }

    items.value = [...allSuggests].slice(0, SUGGESTS_COUNT);
  } catch (e) {
    clearSuggests(true);
    status.value = "error";
  }

  status.value = "success";
};

const showEmptyGoods = computed(
  () =>
    openSearchSuggest.value &&
    searchValue.value.length >= SEARCH_CHAR_START &&
    ["error", "success"].includes(status.value) &&
    !items.value.length,
);

watchDebounced(
  () => searchValue.value,
  async () => {
    await fetchSuggests();
  },
  { debounce: 300 },
);

const toggleSearch = (isOpen: boolean) => {
  searchFocused.value = isOpen;
  openSearchSuggest.value = isOpen;
};

const onClickItem = (category: ICategory) => {
  router.push(category.url);
  toggleSearch(false);
};

const onSearchSubmit = (searchValue: string) => {
  router.push(encodeURI(`${Routes.CatalogSearch}?term=${searchValue}`));
};

watch(openSearchSuggest, (next) => {
  next ? scrollLock.enable() : scrollLock.disable();
});
</script>

<template>
  <div class="header-search-wrapper">
    <VHeaderSearch
      :model-value="searchValue"
      :open-suggest="openSearchSuggest"
      @update:model-value="(e) => (searchValue = e)"
      @update:open-suggest="toggleSearch"
      @submit="onSearchSubmit"
    >
      <VCatalogNavigation
        v-if="status === 'initial'"
        :items="categoriesStore.structure"
        root
        :open="openSearchSuggest"
        @item:click="onClickItem"
      />
      <VSearchSkeleton v-if="status === 'pending'" />
      <VSearchResults
        v-else-if="status === 'success' && items.length"
        :search-url="Routes.CatalogSearch"
        :items="items"
        :query="searchValue"
        @click="openSearchSuggest = false"
        @results="openSearchSuggest = false"
      />
      <div v-else-if="showEmptyGoods" class="search-suggest-error">
        <VEmptyGoods
          @click:primary="closeError"
          @click:secondary="closeError"
        />
      </div>
    </VHeaderSearch>
  </div>
</template>

<style scoped lang="postcss">
.header-search-wrapper {
  padding: var(--pl-unit-x2) var(--pl-unit-x5) var(--pl-unit-x4)
    var(--pl-unit-x5);

  :deep(.catalog-category-step__content) {
    padding: 0 var(--pl-unit-x3);
  }

  :deep(.header-search__suggest) {
    border-top: none;
  }

  :deep(.catalog-category-caption::after) {
    left: var(--pl-unit-x2);
    right: var(--pl-unit-x2);
  }
}

:global(.header-search__suggest) {
  left: unset !important;
}
</style>

<style lang="postcss">
:root {
  --header-project-nav-height: 0px;
}
</style>
